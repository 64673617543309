import axios, {
    AxiosResponse,
    CreateAxiosDefaults,
    InternalAxiosRequestConfig,
} from "axios";
// import {ActionType, storeMgr} from "../appStore";
const defaultAxiosCfg: CreateAxiosDefaults = {
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 0,//没超时时间
    headers: {
        "Content-Type": "application/json",
    },
};

const formDataAxiosCfg: any = {
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 0,//没超时时间
    headers: {
        "Content-Type": "multipart/form-data",
    },
}

const fileFormCfg: any = {
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 0,//没超时时间
    headers: {
        "Content-Type": "application/json",
    },
    responseType: 'arraybuffer'
}

const api = axios.create(defaultAxiosCfg);

const apiForm = axios.create(formDataAxiosCfg);

const fileApi = axios.create(fileFormCfg);

api.interceptors.request.use(
    (cfg: InternalAxiosRequestConfig) => {
        // 这里可以处理请求一些相关配置，例如请求头header
        const token = localStorage.getItem("token");
        if (token) {
            cfg.headers.token = token;
        }
        return cfg;
    },
    (err) => {
        console.error("请求发生错误: ", err);
        return Promise.reject(err);
    }
);
api.interceptors.response.use(
    (res: AxiosResponse) => {
        if (res.data.status === 1) { // 1成功，0失败
            return Promise.resolve(res.data);
        }
        // storeMgr.dispatch({type: ActionType.LOGOUT})
        return Promise.reject(res.data);
    },
    (err) => {
        console.error("网络错误", err);
        return Promise.reject(err);
    }
);

apiForm.interceptors.request.use(
    (cfg: InternalAxiosRequestConfig) => {
        // 这里可以处理请求一些相关配置，例如请求头header
        const token = localStorage.getItem("token");
        if (token) {
            cfg.headers.token = token;
        }
        return cfg;
    },
    (err) => {
        console.error("请求发生错误: ", err);
        return Promise.reject(err);
    }
);

apiForm.interceptors.response.use(
    (res: AxiosResponse) => {
        if (res.data.status === 1) { // 1成功，0失败
            return Promise.resolve(res.data);
        }
        // storeMgr.dispatch({type: ActionType.LOGOUT})
        return Promise.reject(res.data);
    },
    (err) => {
        console.error("网络错误", err);
        return Promise.reject(err);
    }
);

fileApi.interceptors.request.use(
    (cfg: InternalAxiosRequestConfig) => {
        // 这里可以处理请求一些相关配置，例如请求头header
        const token = localStorage.getItem("token");
        if (token) {
            cfg.headers.token = token;
        }
        return cfg;
    },
    (err) => {
        console.error("请求发生错误: ", err);
        return Promise.reject(err);
    }
);


class Http {
    get(url: string) {
        return api.get(url);
    }

    post<T = {}>(url: string, data: T, headers?: any) {
        return api.post(url, data, {
            headers: { ...headers }
        });
    }

    postForm<T = {}>(url: string, data: T, headers?: any) {
        return apiForm.post(url, data, {
            headers: { ...headers }
        });
    }

    postFile<T = {}>(url: string, data: T, headers?: any) {
        return fileApi.post(url, data, {
            headers: { ...headers }
        });
    }
}

export const http = new Http();
