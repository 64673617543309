import React, {useEffect, useState} from "react";
import {Layout as AntLayout, Menu, MenuProps} from "antd";
import {IMenu, useAppContext} from "../../../appStore";
import {Link, useLocation} from "react-router-dom";
import "./index.less";

const {Header, Content, Sider} = AntLayout;

interface ILayoutProps {
    children?: React.ReactNode
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

type flatMenu = IMenu & { pKey: string }
export const Layout = (props: ILayoutProps) => {
    const {children} = props
    const {state: {menus, isLogin},} = useAppContext()
    const [menuSelectedItem, setMenuSelectedItem] = useState<{
        openKeys: string[];
        selectedKeys: string[]
    }>({openKeys: [], selectedKeys: []})
    const location = useLocation()
    const pathname = location.pathname


    useEffect(() => {
        const item = flatMenus(menus || []).find(i => i.url === pathname)
        const selectedItem = {
            openKeys: item ? [item.pKey] : [],
            selectedKeys: item ? [item.url] : []
        }
        setMenuSelectedItem((prevState) => ({
            openKeys: [...prevState.openKeys, ...selectedItem.openKeys],
            selectedKeys: selectedItem.selectedKeys
        }))
    }, [menus, pathname])
    // 构建菜单项
    const getMenuItems = (menus: IMenu[]) => {
        const items: any[] = []
        menus.forEach((item, index) => {
            items.push(getItem(
                <Link className={'menu-link'} to={item.url}>{item.name}</Link>,
                item.url,
                null,
                undefined,
            ))
            if (item.children && item.children.length) {
                items[index].children = getMenuItems(item.children)

            }
        })
        return items
    }
    // 拉平菜单项
    const flatMenus = (menus: IMenu[]) => {
        return menus.reduce((items: flatMenu[], item: IMenu) => {
            const children = item.children
            if (children && children.length) {
                children.forEach((c) => {
                    items.push({...c, pKey: item.url})
                })
            } else {
                items.push({...item, pKey: item.url})
            }
            return items
        }, [])
    }
    const onMenuOpenChange = (openKeys: string[]) => {
        setMenuSelectedItem((prevState) => ({
            ...prevState,
            openKeys
        }))
    }

    if (!isLogin) return <>{children}</>;

    return <AntLayout className="layout">
        <Header className="header">
            <div className="logo"/>
            <span className="layout-header-title">BitShort管理后台</span>
        </Header>
        <AntLayout className={'layout-part2'}>
            <Sider width={200} className={'layout-part2-sider'}>
                <Menu
                    style={{height: '100%', borderRight: 0}}
                    className={'menu-ul'}
                    openKeys={menuSelectedItem.openKeys}
                    selectedKeys={menuSelectedItem.selectedKeys}
                    items={getMenuItems(menus || [])}
                    onOpenChange={onMenuOpenChange}
                    mode="inline"
                />
            </Sider>
            <AntLayout className="layout-content">
                <Content className="layout-content-body">
                    {children}
                </Content>
            </AntLayout>
        </AntLayout>
    </AntLayout>


}
